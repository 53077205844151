import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import TitleC6 from "../../components/Typography/TitleC6";
import ConsultationByPeriod from "../../components/ConsultationByPeriod";
import api from "../../services/api";
import Paginate from "../../components/Paginate/Paginate";
import GraphExtraction from "../../components/Graphs/GraphExtraction";
import requests from "../../services/requests";
import Modal from "../../components/Modals";
import ClipLoader from "react-spinners/ClipLoader";

const AtualizacoesPage = () => {
    // const filterInformation = useSelector(state => state.filter.value);
    const searchPeriod = useSelector(state => state.period);
    const [chartsData, setChartsData] = useState(null);
    const [lastExtraction, setLastExtraction] = useState(null);
    const [lastImport, setLastImport] = useState(null);
    const [list, setList] = useState([]);
    const [action, setAction] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [modalExtractionComment, setModalExtractionComment] = useState(null);
    const [modalImportComment, setModalImportComment] = useState(null);
    const [loadLastExtraction, setLoadLastExtraction] = useState(false);
    const [loadLastImport, setLoadLastImport] = useState(false);
    const [loadList, setLoadList] = useState(false);
    const [loadQtts, setLoadQtts] = useState(false);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const loadInfos = () => {
        setLoadLastExtraction(true);
        api.get("adm/data-update/last-extraction", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            setLastExtraction(response.data.lastExtractionEndedAt);
            setLoadLastExtraction(false);
        });

        setLoadLastExtraction(true);
        api.get("adm/data-update/last-import", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            setLastImport(response.data.lastImportEndedAt);
            setLoadLastExtraction(false);
        });

        setLoadList(true);
        requests
            .listImports(
                {
                    period: searchPeriod,
                    sortColumn: "createdAt",
                    sortDirection: "DESC",
                },
                meta.current_page > 0 ? meta.current_page : 1
            )
            .then(data => {
                setList(data.data);
                setMeta(data.meta);
                setLoadList(false);
            });

        setLoadQtts(true);
        requests
            .atualizationsQtt({
                period: searchPeriod,
            })
            .then(data => {
                setChartsData(data);
                setLoadQtts(false);
            });
    };

    const actionModal = (extractionComment, importComment) => {
        setOpenModal(!openModal);

        setModalExtractionComment(extractionComment);
        setModalImportComment(importComment);
    };

    useEffect(() => {
        setMeta({
            current_page: 1,
        });

        loadInfos();
    }, [searchPeriod, action]);

    return (
        <Layout>
            <SEO title="Analytics" />
            <Card className="flex justify-between flex-wrap lg:p-3">
                <div className="flex flex-wrap items-center mb-2 lg:m-0">
                    <ConsultationByPeriod defaultText="Últimos 7 dias" />
                    <div className="w-120 flex flex-shrink-0 justify-center ml-5">
                        <div>
                            <p className="font-normal">
                                Última extração:{" "}
                                {loadLastExtraction ? (
                                    <ClipLoader size={20} loading={loadLastExtraction} />
                                ) : (
                                    <>{lastExtraction}</>
                                )}
                            </p>
                            <p className="font-normal">
                                Última atualização:{" "}
                                {loadLastImport ? <ClipLoader size={20} loading={loadLastImport} /> : <>{lastImport}</>}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
            <div className="p-4">
                <Card>
                    <TitleC6>Quantidades</TitleC6>
                    <div className="border-t border-C2 border-solid pb-5 overflow-hidden">
                        <div className="w-full">
                            <h6 className="mt-4">Clientes</h6>
                            <GraphExtraction
                                modality="client"
                                load={loadQtts}
                                data={chartsData && chartsData.client ? chartsData.client : null}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="mt-4">Processos</h6>
                            <GraphExtraction
                                modality="process"
                                load={loadQtts}
                                data={chartsData && chartsData.process ? chartsData.process : null}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="mt-4">Frete</h6>
                            <GraphExtraction
                                modality="freight"
                                load={loadQtts}
                                data={chartsData && chartsData.freight ? chartsData.freight : null}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="mt-4">Tratamento de Dados de Recebimento</h6>
                            <GraphExtraction
                                modality="fix-delivery-data"
                                load={loadQtts}
                                data={chartsData && chartsData["fix-delivery-data"] ? chartsData["fix-delivery-data"] : null}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="mt-4">Tratamento de Dados de Posições de Frete</h6>
                            <GraphExtraction
                                modality="fix-position-data"
                                load={loadQtts}
                                data={chartsData && chartsData["fix-position-data"] ? chartsData["fix-position-data"] : null}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="mt-4">Tratamento de Dados de CTE</h6>
                            <GraphExtraction
                                modality="cte"
                                load={loadQtts}
                                data={chartsData && chartsData["cte"] ? chartsData["cte"] : null}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="mt-4">Benchmark</h6>
                            <GraphExtraction
                                modality="benchmark"
                                load={loadQtts}
                                data={chartsData && chartsData.benchmark ? chartsData.process : null}
                            />
                        </div>
                    </div>
                </Card>
                <Card className="mt-4">
                    <h6 className="">Últimas atualizações</h6>
                    <table className="table-auto mt-6 w-full text-left">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                <td className="text-roxo_oficial pr-3">Status</td>
                                <td className="text-roxo_oficial pr-3">Modalidade da query</td>
                                <td className="text-roxo_oficial pr-3">Modalidade do arquivo</td>
                                <td className="text-roxo_oficial pr-3">Data</td>
                                <td className="text-roxo_oficial pr-3">Início da extração</td>
                                <td className="text-roxo_oficial pr-3">Fim da extração</td>
                                <td className="text-roxo_oficial pr-3">Início da importação</td>
                                <td className="text-roxo_oficial pr-3">Fim da importação</td>
                                <td className="text-roxo_oficial pr-3">Arquivos totais</td>
                                <td className="text-roxo_oficial pr-3">Arquivos importados</td>
                                <td className="text-roxo_oficial pr-3">Arquivos pulados</td>
                                <td className="text-roxo_oficial pr-3">Arquivos com erro</td>
                                <td className="text-roxo_oficial pr-3">Comentários</td>
                            </tr>
                        </thead>
                        <tbody>
                            {loadList && <ClipLoader size={20} loading={loadList} />}
                            {list.length > 0 ? (
                                <>
                                    {list.map(item => {
                                        var qttSucess = 0;
                                        var qttSkipped = 0;
                                        var qttError = 0;
                                        item.files.forEach(file => {
                                            if (file.status === "SUCCESS") {
                                                qttSucess++;
                                            } else if (file.status === "SKIPPED") {
                                                qttSkipped++;
                                            } else if (
                                                file.status === "ERROR" ||
                                                file.status === "ERROR_IDLENESS" ||
                                                file.status === "ERROR_DATA_UPDATE"
                                            ) {
                                                qttError++;
                                            }
                                        });
                                        return (
                                            <tr>
                                                <td className="min-w-100 pr-5 text-roxo_oficial text-center">
                                                    {item.status}
                                                </td>
                                                <td className="min-w-100 pr-5 text-center">{item.queryModality}</td>
                                                <td className="min-w-100 pr-5 text-center">{item.fileModality}</td>
                                                <td className="min-w-100 pr-5">{item.createdAt}</td>
                                                <td className="min-w-100 pr-5">{item.extractionStartedAt}</td>
                                                <td className="min-w-100 pr-5">{item.extractionEndedAt}</td>
                                                <td className="min-w-100 pr-5">{item.importStartedAt}</td>
                                                <td className="min-w-100 pr-5">{item.importEndedAt}</td>
                                                <td className="min-w-100 pr-5">{item.files.length}</td>
                                                <td className="min-w-100 pr-5">{qttSucess}</td>
                                                <td className="min-w-100 pr-5">{qttSkipped}</td>
                                                <td className="min-w-100 pr-5">{qttError}</td>

                                                <td className="min-w-100 text-center">
                                                    {(!item.extractionComments || item.extractionComments.length === 0) &&
                                                    (!item.exportComments || item.exportComments.length === 0) ? (
                                                        <> - </>
                                                    ) : (
                                                        <button
                                                            onClick={() => {
                                                                actionModal(item.extractionComments, item.importComments);
                                                            }}
                                                            className="bg-C1 rounded-md my-1 text-roxo_oficial outline-none hover:bg-C3 p-2 w-24"
                                                        >
                                                            <span>Visualizar</span>
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="9" className="text-center py-5">
                                        Nenhum resultado encontrado
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} />
                    )}
                </Card>
            </div>
            <Modal
                show={openModal}
                onClose={actionModal}
                size="xs:w-11/12 lg:w-10/12 min-h-80%"
                title={
                    <div className="flex items-center">
                        <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>Comentários</h5>
                    </div>
                }
            >
                {(!modalExtractionComment || modalExtractionComment.length === 0) &&
                    (!modalImportComment || modalImportComment.length === 0) && <>Sem comentários</>}
                {modalExtractionComment && modalExtractionComment.length > 0 && (
                    <>
                        <h6 className={`mr-6 text-roxo_oficial font-bold`}>Extração</h6>
                        <pre className="font-normal" dangerouslySetInnerHTML={{ __html: modalExtractionComment }} />
                    </>
                )}
                {modalImportComment && modalImportComment.length > 0 && (
                    <>
                        <h6 className={`mr-6 text-roxo_oficial font-bold`}>Importação</h6>
                        <pre className="font-normal" dangerouslySetInnerHTML={{ __html: modalImportComment }} />
                    </>
                )}
            </Modal>
        </Layout>
    );
};

export default AtualizacoesPage;
