import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import ClipLoader from "react-spinners/ClipLoader";
import { colors } from "../../../assets/colors";
import { customLabels } from "../shared/layout";

am4core.useTheme(am4themesAnimated);

const GraphExtraction = ({ modality = "all", data = null, load = false }) => {
    const x = useRef(null);

    useLayoutEffect(() => {
        // Create chart instance
        const chart = am4core.create("extractionGraph-" + modality, am4charts.XYChart);

        chart.colors.list = [am4core.color(colors.roxo_oficial)];

        // Export
        // chart.exporting.menu = new am4core.ExportMenu();

        chart.language.locale = am4lang_pt_BR;

        /* Create axes */
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "status";
        categoryAxis.renderer.minGridDistance = 5;
        const categoryLabel = categoryAxis.renderer.labels;
        customLabels(categoryLabel);

        /* Create value axis */
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        const valueLabel = valueAxis.renderer.labels;
        customLabels(valueLabel);

        /* Create series */
        const columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = "Valor";
        columnSeries.dataFields.valueY = "qtt";
        columnSeries.dataFields.categoryX = "status";

        columnSeries.columns.template.tooltipText =
            "[#fff font-size: 15px]{name} em {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";
        columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
        columnSeries.columns.template.propertyFields.stroke = "stroke";
        columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
        columnSeries.columns.template.width = am4core.percent(40);

        columnSeries.tooltip.label.textAlign = "middle";

        if (data) {
            chart.data = data;
        }

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [data]);

    return (
        <>
            {load && <ClipLoader size={20} loading={load} />}
            <div id={"extractionGraph-" + modality} style={{ width: "100%", height: "300px" }} />
        </>
    );
};

export default GraphExtraction;
